.github-invader {
    --main-color: #24292e; /* GitHub's dark color */
    --glow-color: #6e5494; /* GitHub's purple */
    position: relative;
    width: 150px;
    margin: 2rem auto;
  }
  
  .antenna {
    position: absolute;
    width: 4px;
    background: var(--main-color);
    height: 30px;
    top: -30px;
  }
  .antenna.left { left: 30%; transform: rotate(-10deg); }
  .antenna.right { right: 30%; transform: rotate(10deg); }
  
  .head {
    background: var(--main-color);
    width: 100px;
    height: 100px;
    border-radius: 15px;
    position: relative;
    margin: 0 auto;
    box-shadow: 0 0 15px var(--glow-color);
  }
  
  .eye {
    width: 20px;
    height: 30px;
    background: #fff;
    position: absolute;
    top: 25px;
    border-radius: 5px;
  }
  .eye.left { left: 15px; }
  .eye.right { right: 15px; }
  
  .mouth {
    width: 50px;
    height: 10px;
    background: #fff;
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 5px;
  }
  
  .version-tag {
    position: absolute;
    /* bottom: -25px; */
    right: -10px;
    background: var(--glow-color);
    color: white;
    padding: 2px 8px;
    border-radius: 3px;
    font-family: monospace;
    font-size: 0.8rem;
  }
  .github-invader {
    animation: float 3s ease-in-out infinite;
  }
  
  @keyframes float {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
  }