/* ====== GLOBAL VARIABLES ====== */
:root {
  --primary-color: #2a7fff;
  --secondary-color: #333333;
  --accent-color: #25D366;
  --background-color: #f8f9fa;
  --text-color: #555;
  --border-radius: 10px;
  --transition-speed: 0.3s;
}

/* ====== BASE STYLES ====== */
.resume {
  font-family: 'Inter', Arial, sans-serif;
  color: var(--secondary-color);
  background-color: #f9f9f9;
  line-height: 1.6;
  margin: 0;
  padding: 20px 0;
  min-height: 100vh;
}

.resume .container {
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: var(--border-radius);
}

/* ====== HEADER STYLES ====== */
.resume .header {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
  padding: 20px;
  background-color: #f0f0f0;
  border-radius: var(--border-radius);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: all var(--transition-speed) ease;
}

.resume .header-left {
  display: flex;
  align-items: center;
  flex: 4;
  gap: 20px;
}

.resume .profile-picture {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
  border: 4px solid var(--primary-color);
  flex-shrink: 0;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform var(--transition-speed) ease;
}

.resume .profile-picture:hover {
  transform: scale(1.05);
}

.resume .profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.resume .header-text h1 {
  font-size: 28px;
  margin-bottom: 5px;
  color: #333;
}

.resume .header-text .job-title {
  font-size: 20px;
  color: var(--text-color);
}

.resume .vertical-bar {
  width: 2px;
  height: 120px;
  background-color: #ccc;
  margin: 0 20px;
  flex-shrink: 0;
}

.resume .header-right {
  flex: 2;
  padding-left: 20px;
}

/* ====== CONTACT INFO STYLES ====== */
.resume .contact-info {
  font-size: 14px;
  color: var(--text-color);
}

.resume .contact-info p {
  margin: 10px 0;
  display: flex;
  align-items: center;
  gap: 10px;
}

.resume .contact-info a {
  color: var(--text-color);
  text-decoration: none;
  transition: all var(--transition-speed) ease;
}

.resume .contact-info a:hover {
  color: var(--primary-color);
  text-decoration: underline;
  transform: translateX(2px);
}

/* Icon colors */
.resume .fa-map-marker-alt {
  color: #ff4d4d;
}

.resume .fa-envelope {
  color: #ff9800;
}

.resume .fa-globe {
  color: #9c27b0;
}

.resume .fa-linkedin {
  color: #0077b5;
}

/* ====== SECTION STYLES ====== */
.resume .section {
  padding: 1.5rem;
  background-color: var(--background-color);
  border-radius: var(--border-radius);
  margin-bottom: 1.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all var(--transition-speed) ease;
}

.resume .section-header {
  font-size: 1.5em;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: var(--primary-color);
  margin-bottom: 1.5rem;
  position: relative;
  padding-left: 1.5rem;
  cursor: pointer;
  user-select: none;
  transition: all var(--transition-speed) ease;
}

.resume .section-header:hover {
  color: var(--secondary-color);
}

.resume .section-header::before {
  content: "▹";
  position: absolute;
  left: 0;
  color: var(--primary-color);
}

.resume .section-header::after {
  content: "▼";
  position: absolute;
  right: 1rem;
  font-size: 0.8em;
  transition: transform var(--transition-speed) ease;
}

.resume .section-header.collapsed::after {
  transform: rotate(-90deg);
}

/* ====== CONTENT TRANSITIONS ====== */
.resume .content {
  overflow: hidden;
  transition:
    opacity var(--transition-speed) ease,
    height var(--transition-speed) ease;
  opacity: 1;
  height: auto;
}

.resume .content.collapsed {
  opacity: 0;
  height: 0;
  overflow: hidden;
}

/* ====== EXPERIENCE TIMELINE STYLES ====== */
.resume .experience-timeline {
  position: relative;
  padding-left: 30px;
}

.resume .experience-timeline::before {
  content: '';
  position: absolute;
  left: 5px;
  top: 0;
  bottom: 0;
  width: 2px;
  background: var(--primary-color);
  z-index: 0;
}

.resume .timeline-item {
  position: relative;
  padding-bottom: 25px;
}

.resume .timeline-item:last-child {
  padding-bottom: 0;
}

.resume .timeline-dot {
  position: absolute;
  left: -30px;
  top: 20px;
  width: 12px;
  height: 12px;
  background: var(--primary-color);
  border-radius: 50%;
  border: 3px solid white;
  z-index: 1;
}

.resume .timeline-content {
  padding-left: 15px;
  background: white;
  border-radius: 8px;
  padding: 15px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.resume .item-date {
  font-size: 0.9em;
  color: var(--primary-color);
  background-color: rgba(42, 127, 255, 0.1);
  padding: 4px 8px;
  border-radius: 4px;
  font-family: monospace;
  display: inline-block;
  margin-bottom: 12px;
}

.resume .item-title {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 5px;
  font-size: 1.1em;
  color: var(--secondary-color);
}

.resume .item-company {
  margin-bottom: 15px;
  font-weight: 500;
  color: var(--text-color);
  font-size: 0.95em;
}

.resume .item-description {
  margin-top: 15px;
}

.resume .item-description p {
  position: relative;
  margin-bottom: 12px;
  line-height: 1.5;
  padding-left: 15px;
  font-size: 0.95em;
  color: var(--text-color);
}

.resume .item-description p::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0.6em;
  width: 8px;
  height: 2px;
  background: var(--primary-color);
}

/* Hover effects */
.resume .timeline-item:hover .timeline-content {
  transform: translateX(5px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.resume .timeline-item:hover .timeline-dot {
  transform: scale(1.2);
}

/* Transitions */
.resume .timeline-content,
.resume .timeline-dot {
  transition: all 0.3s ease;
}

/* ====== SKILLS STYLES ====== */
.skills-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
}

.skill-item {
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.skill-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.skill-strength {
  display: flex;
  gap: 4px;
}

.strength-dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: #e0e0e0;
}

.strength-dot.filled {
  background: var(--primary-color);
}

.skill-description {
  font-size: 0.9em;
  color: var(--text-color);
}

/* ====== CERTIFICATIONS STYLES ====== */
.certifications-list {
  list-style: none;
  padding: 0;
}

.certification-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.certification-item:last-child {
  border-bottom: none;
}

.cert-icon {
  color: var(--primary-color);
}

/* ====== PROJECTS STYLES ====== */
.project-technologies {
  font-size: 0.9em;
  margin-top: 8px;
  color: var(--text-color);
}

/* ====== LANGUAGES STYLES ====== */
.languages-list {
  list-style: none;
  padding: 0;
}

.language-item {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 8px 0;
}

.lang-icon {
  color: var(--primary-color);
}

/* ====== RESPONSIVE DESIGN ====== */
@media (max-width: 768px) {
  .resume .header {
    flex-direction: column;
    text-align: center;
  }

  .resume .header-left {
    flex-direction: column;
    margin-bottom: 15px;
    gap: 10px;
  }

  .resume .vertical-bar {
    display: none;
  }

  .resume .header-right {
    padding-left: 0;
    text-align: center;
  }

  .resume .contact-info p {
    justify-content: center;
  }

  .resume .experience-timeline {
    padding-left: 25px;
  }

  .resume .timeline-dot {
    left: -25px;
  }

  .resume .timeline-content {
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .resume .section-header {
    font-size: 1.3em;
    padding-right: 1.5rem;
  }

  .resume .experience-timeline {
    padding-left: 20px;
  }

  .resume .timeline-dot {
    left: -19px;
    width: 10px;
    height: 10px;
  }

  .resume .item-description p {
    padding-left: 12px;
  }

  .resume .item-description p::before {
    width: 6px;
  }

  .resume .item-block {
    margin-left: 1rem;
    padding-left: 1rem;
  }
}