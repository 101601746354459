/* ======================
   Project Card Container
   ====================== */
   .project-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1rem;
}

/* =================
   Project Card Core
   ================= */
.project-card {
    display: flex;
    flex-direction: column;
    height: 100%;
    flex: 1 1 auto;
    min-width: 500px;
    background: white;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 15px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.project-card:hover {
    transform: translateY(-5px);
    border-color: var(--gradient-end, #007bff);
    box-shadow: 0 12px 20px rgba(0, 123, 255, 0.15);
}

/* ==============
   Card Elements
   ============== */
/* Image Container */
.image-container {
    width: 180px;
    height: 180px;
    margin: 2rem auto;
    border: 4px solid var(--gradient-end, #007bff);
    border-radius: 50%;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease;
}

.image-container:has(.placeholder-image) {
    border-color: #cbd5e0;
}

.image-container:hover img {
    transform: scale(1.1);
}

.project-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.3s ease;
}

/* Placeholder Styles */
.placeholder-image {
    filter: grayscale(100%);
    opacity: 0.7;
    background: linear-gradient(45deg, #f0f4f8 25%, #e3e9ef 50%, #f0f4f8 75%);
    background-size: 200% 200%;
}

.image-container:has(.placeholder-image):hover img {
    transform: none;
}

.placeholder-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: #94a3b8;
    font-weight: 600;
    text-align: center;
    z-index: 1;
    pointer-events: none;
}

/* Card Body */
.project-body {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 1.5rem 1rem;
}

/* Title Styling */
.project-title {
    margin: 0;
    font-size: 1.7rem;
    font-weight: 700;
    color: var(--gradient-end, #007bff);
    text-align: center;
    position: relative;
    letter-spacing: -0.5px;
    text-transform: uppercase;
}

.project-title::before,
.project-title::after {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    width: 30px;
    background: linear-gradient(90deg, 
        rgba(0, 123, 255, 0.3) 0%,
        var(--gradient-end, #007bff) 50%,
        rgba(0, 123, 255, 0.3) 100%);
}

.project-title::before { left: -40px; }
.project-title::after { right: -40px; }

.project-card:hover .project-title {
    color: var(--gradient-start, #0056b3);
}

.project-card:hover .project-title::before,
.project-card:hover .project-title::after {
    width: 50px;
    background: var(--gradient-end, #007bff);
}

/* Description Section */
.description-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.project-description {
    margin: 0;
    line-height: 1.6;
    color: var(--text-dark, #4a5568);
    text-align: justify;
}

/* =============
   Buttons
   ============= */
.project-buttons {
    display: flex;
    gap: 1rem;
    justify-content: center;
    padding: 0 1rem 1.5rem;
}

.btn {
    padding: 0.75rem 2rem;
    border-radius: 8px;
    font-weight: 600;
    letter-spacing: 0.5px;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
}

.btn-demo {
    background: linear-gradient(135deg, #ffffff 0%, #f8f9fa 100%);
    border: 2px solid var(--gradient-end, #007bff);
    color: var(--gradient-end, #007bff);
    box-shadow: 0 2px 4px rgba(0, 123, 255, 0.1);
}

.btn-demo:hover {
    background: linear-gradient(135deg, var(--gradient-end, #007bff) 0%, #0056b3 100%);
    color: white;
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

.btn-code {
    background: linear-gradient(135deg, var(--gradient-end, #007bff) 0%, #0056b3 100%);
    color: white;
    border: 2px solid transparent;
    box-shadow: 0 2px 4px rgba(0, 123, 255, 0.1);
}

.btn-code:hover {
    background: linear-gradient(135deg, #0056b3 0%, #003d80 100%);
    transform: translateY(-2px);
    box-shadow: 0 4px 8px rgba(0, 123, 255, 0.2);
}

/* ====================
   Animations
   ==================== */
@keyframes placeholder-shimmer {
    0% { background-position: 100% 100%; }
    100% { background-position: -100% -100%; }
}

/* ====================
   Responsive Design
   ==================== */
@media (max-width: 768px) {
    .project-body {
        gap: 1.5rem;
        padding: 1rem;
    }
    
    .description-container {
        gap: 1rem;
    }
    
    .project-title {
        font-size: 1.4rem;
        letter-spacing: -0.3px;
    }
    
    .project-title::before,
    .project-title::after {
        width: 20px;
    }
    
    .project-card:hover .project-title::before,
    .project-card:hover .project-title::after {
        width: 30px;
    }
    
    .project-description {
        text-align: left;
    }
}