/* ====================== */
/* ==== Variables ======= */
/* ====================== */
:root {
  --primary-bg: #92867f;
  --gradient-start: #3c2c52;
  --gradient-end: #75b531;
  --text-light: #adb5ba;
  --text-dark: #381263;
  --hover-scale: 1.05;
}

/* ====================== */
/* === Layout Styles ==== */
/* ====================== */
.main-div {
  display: grid;
  grid-template-rows: auto 1vh minmax(60vh, 1fr) auto;
  min-height: 100vh;
  padding-top: 125px;
  /* Adjust for fixed hero */
  padding-bottom: 10vh;
  /* Adjust for fixed footer */
}

.topSide,
.hero-cont {
  position: fixed;
  width: 100%;
  height: 55px;
  /* Minimum height */
  background: var(--primary-bg) url("./assets/images/backgroundTop.png") no-repeat center center/cover;
  /* Added background-size */
  z-index: 1000;
}

.middle {
  background: linear-gradient(to right, var(--gradient-start), var(--gradient-end));
  height: 1vh;
}

.bottomSide {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 75px;
  padding: 0 120px;
  min-height: 10vh;
}

.footer-div {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 15px;
  background: var(--primary-bg);
  z-index: 1000;
  /* Ensures it stays on top */
}

/* ====================== */
/* === Navbar Styles ==== */
/* ====================== */
.navbar {
  padding: 0.5rem 1rem;
  min-height: 60px;
}

.navbar-light .navbar-nav {
  align-items: center;
}

/* Base nav links */
.navbar-light .navbar-nav .nav-link {
  color: var(--text-light) !important;
  font-size: 1rem;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  position: relative;
  will-change: color, font-size;
  padding: 0.5rem 1rem;
}

/* Hover effects */
.navbar-light .navbar-nav .nav-link:hover {
  color: var(--text-dark) !important;
  transform: scale(var(--hover-scale));
}

/* ====================== */
/* === Language Flags === */
/* ====================== */
.language-flags {
  display: flex;
  gap: 0.8rem;
  margin-left: 1.2rem;
  align-items: center;
}

.flag-btn {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.flag-img {
  width: 24px;
  height: 18px;
  /* 4:3 aspect ratio */
  object-fit: cover;
  border-radius: 1px;
  opacity: 0.9;
  transition: opacity 0.2s ease;
}

/* Underline Animation */
.flag-btn::after {
  content: '';
  position: absolute;
  bottom: 3px;
  /* Closer to flag */
  left: 0;
  width: 100%;
  height: 1.5px;
  background: var(--gradient-end);
  transform: scaleX(0);
  transform-origin: center;
  transition: transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Hover Effects */
.flag-btn:hover .flag-img {
  opacity: 1;
}

.flag-btn:hover::after {
  transform: scaleX(0.7);
}

/* Active Language */
.flag-btn.active::after {
  transform: scaleX(0.9);
  background: linear-gradient(to right,
      var(--gradient-start),
      var(--gradient-end));
  height: 2px;
  bottom: 3px;
}

.flag-btn.active .flag-img {
  opacity: 1;
  filter: saturate(1.2);
}

/* Mobile Optimization */
@media (max-width: 768px) {
  .language-flags {
    gap: 0.6rem;
    margin-left: 0.8rem;
  }

  .flag-img {
    width: 20px;
    height: 15px;
  }

  .flag-btn::after {
    bottom: -2px;
    height: 1px;
  }

  .flag-btn.active::after {
    height: 1.5px;
    bottom: -1px;
  }
}

/* Mobile menu */
.navbar-light .navbar-toggler {
  border-color: rgba(173, 181, 186, 0.5);
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='rgba(173, 181, 186, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
  transition: transform 0.3s ease;
}

.navbar-light .navbar-toggler:hover .navbar-toggler-icon {
  transform: scale(1.1);
}

/* ====================== */
/* === Card Styles ====== */
/* ====================== */
.p-card {
  margin: 1.5rem;
  transition: transform 0.3s ease;
}

.p-card:hover {
  transform: scale(var(--hover-scale));
}

.p-card-image {
  width: 100px;
  height: 100px;
  border-radius: 100px;
  box-shadow: 0px 0px 3px 1px #ccc;
  object-fit: cover;
}

.p-card-info {
  margin-top: 0.75rem;
  min-height: 50px;
  max-width: 150px;
}

.p-card-title {
  font-size: 1.5rem;
  margin: 0;
}

.p-card-subtitle {
  font-size: 0.875rem;
  margin: 0;
}

/* ====================== */
/* === Icon Styles ====== */
/* ====================== */
.home-icons-div {
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-icons,
.center-icon {
  border: none;
  border-radius: 50%;
  transition: transform 0.3s ease;
}

.home-icons {
  width: 130px;
  height: 130px;
  padding: 10px;
}

.center-icon {
  width: 200px;
  height: 200px;
  padding: 10px;
}

.home-icons:hover,
.center-icon:hover {
  transform: scale(var(--hover-scale));
}

/* ====================== */
/* === Responsive Styles = */
/* ====================== */
@media (max-width: 992px) {
  .bottomSide {
    padding: 0 2rem;
    grid-template-rows: auto;
  }

  .language-flags {
    margin-left: 0;
    justify-content: center;
    padding: 1rem 0;
  }
}

@media (max-width: 768px) {
  .bottomSide {
    padding: 0 1rem;
    margin-top: 2rem;
  }

  .navbar-light .navbar-nav .nav-link {
    padding: 0.5rem 0;
  }
}